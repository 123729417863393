import { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import moment from 'moment';
import { getTransactionType } from 'app/modules/inventory/components/TransactionHistory/utils/transactionTypeFilter';

import Loader from '../../../../../components/Loader';
import FormTextInput from '../../../../../components/ProcureForm/FormTextInput';
import Assets from '../../../../../i18n/Assets';
import Inventory from '../../../../../i18n/Inventory';
import { TransactionEntityTypeEnum, TransactionSubTypeEnum } from '../../../../../types/schema';
import EntityManager from '../../../../components/EntityManager';
import useEntityManager from '../../../../components/EntityManager/useEntityManager';
import { useTransactionDataQuery } from './graphql/queries/generated/transactionData';
import {
  getAuditTransactionColumns,
  getMoveCheckOutTransactionColumns,
  getMoveTransactionColumns,
  getRemoveTransactionColumns,
  getReserveTransactionColumn,
  getRestockTransactionColumns,
} from './utils/columns';
import { getTitleByTransactionType } from './utils/titleUtils';

type Props = { parentTransactionId: string };

const EditTransactions = (props: Props) => {
  const { parentTransactionId } = props;

  const [{ fetching, data }] = useTransactionDataQuery({
    variables: {
      filters: {
        parentTransactionIds: [parentTransactionId],
      },
    },
  });

  const transactions = useMemo(() => {
    return data?.transactions?.edges?.map(({ node }) => node) || [];
  }, [data?.transactions?.edges]);

  const transactionData = useMemo(() => {
    return transactions.length ? transactions[0] : undefined;
  }, [transactions]);

  const isEntityTypeAsset = [
    TransactionEntityTypeEnum.Asset,
    TransactionEntityTypeEnum.AssetKit,
  ].includes(transactionData?.entityType as TransactionEntityTypeEnum);

  const tableTitle = isEntityTypeAsset ? Assets.AssetItems : Inventory.InventoryItems;

  const { documentTitle, title } = useMemo(() => {
    return getTitleByTransactionType(
      transactionData?.type,
      transactionData?.subType,
      transactionData?.entityType,
    );
  }, [transactionData]);

  const { table } = useEntityManager({
    selection: false,
  });

  const tableColumns = useMemo((): DataGridHeadCell[] => {
    const transactionType =
      transactionData?.type && transactionData?.subType
        ? getTransactionType(transactionData?.type, transactionData?.subType)
        : undefined;

    if (!transactionType || !transactionData) {
      return [];
    }

    switch (transactionType.id) {
      case TransactionSubTypeEnum.Restock:
        return getRestockTransactionColumns(transactionData.entityType);
      case TransactionSubTypeEnum.Remove:
        return getRemoveTransactionColumns();
      case TransactionSubTypeEnum.Checkout:
        return getMoveCheckOutTransactionColumns();
      case TransactionSubTypeEnum.Audit:
        return getAuditTransactionColumns();
      case TransactionSubTypeEnum.Reserve:
        return getReserveTransactionColumn();
      default:
        return getMoveTransactionColumns(transactionData.subType);
    }
  }, [transactionData]);

  if (fetching) {
    return <Loader />;
  }

  if (!transactionData) {
    return null;
  }

  return (
    <EntityManager className="rounded-bl-[12px] rounded-br-[12px] border border-t-0 border-grey-300 !pt-[0px]">
      <EntityManager.Title documentTitle={documentTitle} title={title} hasTopDivider />

      <Box className="mt-[24px]">
        <Box className="!mt-[24px] w-full sm:max-w-[382px]  md:min-w-[382px] lg:max-w-[382px] xl:max-w-[382px]">
          <FormTextInput
            disabled
            className="mt-20"
            label={Inventory.FormLabels.Date}
            value={moment(transactionData.createdAt).format('L LT')}
            formLabel="Date"
          />
          <Box className="mt-[24px]">
            <FormTextInput
              disabled
              className="mt-[24px]"
              label={Inventory.FormLabels.User}
              value={`${transactionData.createdBy.firstName} ${transactionData.createdBy.lastName}`}
              formLabel="User"
            />
          </Box>
        </Box>
      </Box>

      <div className="mt-20 mb-20">
        <EntityManager.Title title={tableTitle} />
        <Box
          className="mt-[24px] rounded-[12px] border border-grey-300"
          sx={{
            boxShadow:
              '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
          }}>
          <EntityManager.Table
            actions={table.actions}
            columns={tableColumns}
            data={transactions}
            pagination={true}
            persistKey="edit_transaction_table"
            setState={table.setState}
            state={table.state}
            total={transactions.length}
            paginationWrapperClass="p-[24px]"
          />
        </Box>
      </div>
    </EntityManager>
  );
};

export default EditTransactions;
