import _, { isNumber } from 'lodash';

import { ItemTypeEnum, Maybe, OrderRequestItemStatusEnum, OrderRequestItemTypeEnum } from '../../../../../../../types/schema';
import { getSupportedImageAttachments } from '../../../../../../../utils/attachments';
import { ItemInStock, OrderRequestItemSchema } from '../../../../OrderRequest/type';

export function updateOrderItem(
  orderItems: OrderRequestItemSchema[],
  orderItemId: string,
  updateState: Partial<OrderRequestItemSchema>,
) {
  return _.map(orderItems, (orderItem) => {
    if (orderItem.id === orderItemId) {
      return {
        ...orderItem,
        ...updateState,
        isEdited: true,
      } as OrderRequestItemSchema;
    }

    return orderItem;
  });
}

export function updateOrderItems(data: {
  orderRequestItems: OrderRequestItemSchema[];
  updateState: Partial<OrderRequestItemSchema>[];
}) {
  const { orderRequestItems, updateState } = data;
  return _.map(orderRequestItems, (orderItem) => {
    const item = updateState.find((updateStateItem) => updateStateItem.id === orderItem.id);
    if (item) {
      return {
        ...orderItem,
        ...item,
        isEdited: true,
      };
    }
    return orderItem;
  });
}

export function findOrderItemById(orderItems: OrderRequestItemSchema[], orderItemId: string) {
  return _.find(orderItems, (orderItem) => orderItem.id === orderItemId);
}

function getOrderRequestItemTypeFromItemInStock(itemInStock: ItemInStock) {
  switch (itemInStock.type) {
    case ItemTypeEnum.Asset:
      return OrderRequestItemTypeEnum.Asset;
    case ItemTypeEnum.AssetKit:
      return OrderRequestItemTypeEnum.AssetKit;
    case ItemTypeEnum.Inventory:
      return OrderRequestItemTypeEnum.Inventory;
    case ItemTypeEnum.InventoryKit:
      return OrderRequestItemTypeEnum.InventoryKit;
    default:
      return OrderRequestItemTypeEnum.NoSku;
  }
}

export function parseItemInStockToExistingOrderItem(
  orderItem: OrderRequestItemSchema,
  itemInStock: ItemInStock,
) {
  const { description, cost, attachments, id, sku, title, type, categoryId } = itemInStock;
  return {
    ...orderItem,
    cost: isNumber(cost) ? (cost as unknown as string) : 0,
    description: description,
    upcCode: undefined,
    website: undefined,
    imageUrl: getSupportedImageAttachments(attachments)?.[0]?.url || null,
    itemId: id,
    item: {
      id,
      sku,
      title,
      categoryId,
      cost,
      type,
    },
    itemIdInPartnerTenant: id,
    type: getOrderRequestItemTypeFromItemInStock(itemInStock),
  } as OrderRequestItemSchema;
}

const isDuplicateItemInStock = (
  existingItem: OrderRequestItemSchema,
  newOrderItem: OrderRequestItemSchema,
  options: {
    isParentTenantOrderView: boolean;
    isChildTenantOrderView: boolean;
  },
) => {
  const { itemId, projectId, status, itemIdInPartnerTenant } = existingItem;
  const {
    itemId: newItemId,
    projectId: newProjectId,
    itemIdInPartnerTenant: newItemIdInPartnerTenant,
    status: newItemStatus,
  } = newOrderItem;
  let isItemIdMatch = Boolean(itemId && itemId === newItemId);
  let isExternalIdMatch = false;
  
  let isStatusMatch = (newItemStatus === OrderRequestItemStatusEnum.Open && status === OrderRequestItemStatusEnum.Open) || 
  (newItemStatus === OrderRequestItemStatusEnum.BackOrdered && status === OrderRequestItemStatusEnum.BackOrdered) || 
  (newItemStatus === OrderRequestItemStatusEnum.Ordered && status === OrderRequestItemStatusEnum.Ordered);

  if (options.isChildTenantOrderView) isExternalIdMatch = Boolean(itemIdInPartnerTenant && itemIdInPartnerTenant === newItemId);

  return (isItemIdMatch || isExternalIdMatch) && isStatusMatch && (projectId === newProjectId || (!projectId && !newProjectId));
};

const isDuplicateUpcCode = (
  existingItem: OrderRequestItemSchema,
  newOrderItem: OrderRequestItemSchema,
) => {
  const existingProjectId = existingItem?.projectId || '';
  const newProjectId = newOrderItem?.projectId || '';
  return (
    existingItem.upcCode &&
    existingItem.upcCode === newOrderItem.upcCode &&
    existingProjectId === newProjectId
  );
};

export function getDuplicateOrderItem(
  orderRequestItems: OrderRequestItemSchema[],
  currentOrderItemId: string,
  options: {
    isParentTenantOrderView: boolean;
    isChildTenantOrderView: boolean;
  },
): {
  duplicateOrderItem: Maybe<OrderRequestItemSchema>;
  duplicateUpdatedState: Partial<OrderRequestItemSchema>;
} {
  let currentOrderItem = findOrderItemById(orderRequestItems, currentOrderItemId);
  let duplicateOrderItem: Maybe<OrderRequestItemSchema> = null;

  if (!currentOrderItem) {
    return {
      duplicateOrderItem,
      duplicateUpdatedState: {
        quantity: 0,
      },
    };
  }

  for (let i = 0; i < orderRequestItems.length; i++) {
    const orderRequestItem = orderRequestItems[i];
    const { id } = orderRequestItem;

    if (
      id !== currentOrderItemId &&
      (isDuplicateItemInStock(orderRequestItem, currentOrderItem, options) ||
        isDuplicateUpcCode(orderRequestItem, currentOrderItem))
    ) {
      duplicateOrderItem = orderRequestItem;
      break;
    }
  }

  const totalQuantity = duplicateOrderItem
    ? Number(duplicateOrderItem.quantity || 0) + currentOrderItem.quantity
    : 0;

  const item = options?.isChildTenantOrderView ? currentOrderItem?.item : duplicateOrderItem?.item;

  return {
    duplicateOrderItem,
    duplicateUpdatedState: {
      itemId: item?.id || '',
      item: {
        ...(duplicateOrderItem?.item || {}),
        ...item,
      } as any,
      quantity: totalQuantity,
      cost: currentOrderItem.cost,
    },
  };
}
